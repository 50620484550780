import RequestContainer from 'components/containers/request.container';
import SubpageContainer from 'components/containers/subpage.container';
import FormInput from 'components/forms/form-input';
import FormSubmit from 'components/forms/form-submit';
import useFetch from 'hooks/useFetch';
import RebatesShimmer from 'pages/users/funnels/shimmers/rebates';
import { Form } from 'react-final-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks';
import { selectRequest } from 'request/request.slice';
import {
  addCustomerActivity,
  getCustomerActivityDetails,
  updateCustomerActivity,
} from 'pages/admin/customer-activity/customer-activity.thunks';
import FormSelect from '../../../components/forms/form-select';
import React from 'react';

function CustomerActivityForm() {
  const navigate = useNavigate();
  const { id } = useParams();
  const isCreating = id === 'new';
  let customerActivity = {
    sellerId: '',
    status: '',
  };
  const status = [
    {
      value: 'enabled',
    },
    {
      value: 'disabled',
    },
  ];
  let isFetching = false;

  if (!isCreating) {
    ({ isFetching, data: customerActivity } = useFetch('customer-activity-details', () =>
      getCustomerActivityDetails(id)
    ));
  }

  const onSubmit = async (values) => {
    if (isCreating) {
      const res = await addCustomerActivity(values);
      if (res) {
        setTimeout(() => navigate('/dashboard/customer-activity'), 2000);
      }
    } else {
      await updateCustomerActivity(id, values);
    }
  };

  return (
    <SubpageContainer title={isCreating ? 'Add New CustomerActivity' : 'Update Customer Activity'}>
      <RequestContainer loading={isFetching} fallback={<RebatesShimmer />}>
        <Form
          onSubmit={onSubmit}
          initialValues={customerActivity}
          render={({ handleSubmit }) => {
            const { isLoading } = useAppSelector(selectRequest);
            return (
              <form onSubmit={handleSubmit}>
                <div className="space-y-6 bg-white rounded-md">
                  <div className="grid sm:w-full xl:w-3/4 grid-cols-1 p-6 gap-4">
                    <div className=" grid grid-cols-1 gap-4">
                      <FormInput name="sellerId" label="Seller Id" />
                      <FormSelect
                        values={status}
                        name="status"
                        label="Status"
                        optionValue="value"
                        optionLabel="value"
                        initialValue={status[0]?.value}
                      />
                    </div>
                    <div className="flex justify-end">
                      <FormSubmit label="Submit" showButtonOnly disabled={isLoading} />
                    </div>
                  </div>
                </div>
              </form>
            );
          }}
        />
      </RequestContainer>
    </SubpageContainer>
  );
}

export default CustomerActivityForm;
