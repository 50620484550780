import RequestContainer from 'components/containers/request.container';
import SubpageContainer from 'components/containers/subpage.container';
import Table from 'components/tables/table';
import TableBody from 'components/tables/table-body';
import TableShimmer from 'pages/users/magic-links/magic-link.shimmer';
import React from 'react';
import { useCustomerActivity } from 'pages/admin/customer-activity/customer-activity.hooks';
import CustomerActivityTableData from 'pages/admin/customer-activity/customer-activity.tabledata';
import { useAppSelector } from 'redux/hooks';
import { selectCustomerActivity } from 'pages/admin/customer-activity/customer-activity.slice';

const headers = ['Seller Id', 'status', 'Created Date', 'Action'];

const CustomerActivity = () => {
  const { isFetching } = useCustomerActivity('customer-activity');
  const columns = CustomerActivityTableData();
  const colSpan = headers.length;
  const customerActivityData = useAppSelector(selectCustomerActivity);
  return (
    <>
      <SubpageContainer title="Customer Activity" showAdd addUrl="new" showAddString="Add Seller">
        <Table headers={headers}>
          <RequestContainer
            loading={isFetching}
            fallback={<TableShimmer headers={headers} span={colSpan} />}
            colSpan={colSpan}
            data={customerActivityData.docs}
            noDataMsg="No Sellers"
            buttonLabel="New Seller"
          >
            <TableBody
              columns={columns}
              data={customerActivityData.docs}
              deleteUrl="/sqp/admin/customer-activity/"
              confirmField="name"
            />
          </RequestContainer>
        </Table>
      </SubpageContainer>
    </>
  );
};

export default CustomerActivity;
