import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';
import { CustomerActivityInterface, ResponseCustomerActivityInterface } from 'interfaces/customer-activity.interface';

const initialState: ResponseCustomerActivityInterface = {
  hasNextPage: false,
  hasPrevPage: false,
  limit: 0,
  nextPage: 0,
  page: 0,
  pagingCounter: 0,
  prevPage: 0,
  totalDocs: 0,
  totalPages: 0,
  docs: [],
};

export const customerActivitySlice = createSlice({
  name: 'customerActivity',
  initialState,
  reducers: {
    setCustomerActivity: (
      state: Draft<ResponseCustomerActivityInterface>,
      action: PayloadAction<ResponseCustomerActivityInterface>
    ) => {
      return action.payload;
    },
    deleteCustomerActivity: (state: Draft<ResponseCustomerActivityInterface>, action: PayloadAction<string>) => {
      const { docs: customerActivity } = state;
      const updatedCustomerActivity = customerActivity.filter(
        (u: CustomerActivityInterface) => u._id !== action.payload
      );
      return { ...state, docs: updatedCustomerActivity, totalDocs: updatedCustomerActivity?.length };
    },
  },
});

export const { setCustomerActivity, deleteCustomerActivity } = customerActivitySlice.actions;

export const selectCustomerActivity = (state: RootState) => state.customerActivity;

export const customerActivityReducer = customerActivitySlice.reducer;
