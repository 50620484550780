import { useQuery } from '@tanstack/react-query';
import { getCustomerActivity } from 'pages/admin/customer-activity/customer-activity.thunks';

export const useCustomerActivity = (queryKey: string) => {
  const { data, isFetching } = useQuery({
    queryKey: [queryKey],
    queryFn: async () => await getCustomerActivity(),
    refetchOnWindowFocus: false,
    retry: false,
    cacheTime: 0,
  });

  return {
    isFetching,
    data,
  };
};
