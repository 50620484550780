import {
  BadgeCheckIcon,
  LightBulbIcon,
  TruckIcon,
  UserCircleIcon,
  UsersIcon,
  NewspaperIcon,
} from '@heroicons/react/outline';
import { SidebarGroupedNavigationInterface } from './navigation.interface';

const adminNavigation = (): SidebarGroupedNavigationInterface[] => {
  return [
    {
      name: 'Users',
      subMenu: [{ name: 'Manage Users', href: 'users', icon: UsersIcon, current: false, id: 'users' }],
    },
    {
      name: 'SQP',
      subMenu: [
        { name: 'Brands', href: 'brands', icon: BadgeCheckIcon, current: false, id: 'brands' },
        { name: 'Accounts', href: 'accounts', icon: UserCircleIcon, current: false, id: 'accounts' },
        { name: 'Seller Accounts', href: 'seller-accounts', icon: TruckIcon, current: false, id: 'seller-accounts' },
      ],
    },
    {
      name: 'Prompts',
      subMenu: [{ name: 'Apollo Prompts', href: 'prompts', icon: LightBulbIcon, current: false, id: 'prompts' }],
    },
    {
      name: 'Customer Activity',
      subMenu: [
        { name: 'Sellers', href: 'customer-activity', icon: NewspaperIcon, current: false, id: 'customer-activity' },
      ],
    },
  ];
};

export default adminNavigation;
